import React, { useState, useEffect, useRef } from 'react'
import { useContextState } from '../../context/Context'
import { useGlobalModalContext, MODAL_TYPES } from '../modals/GlobalModal'
import { useEventApi, useShared } from "../../store";
import Loader from '../shared/Loader';
import AlertComponent from '../shared/AlertComponent';
import { Button, Card, Modal } from 'flowbite-react'
import EventsListHome from './EventsListHome';
import ImageCarousel from '../images/ImageCarousel';
import { GiTicket } from 'react-icons/gi';
import { FaRegStar, FaRegShareFromSquare } from 'react-icons/fa6';
import { Share } from '../share';

interface Props{
    eventId?: string;
    event? : any;
    showAddress? : boolean;
    callBack?: () => void;
    ticketCallBack?: (state:string) => void;
}

const EventDetails: React.FC<Props> = ({eventId, event, showAddress, callBack, ticketCallBack}) => {
    
    const { eventsCTX } = useContextState();
    const { eventPageType, dispatchAllEventsState } = eventsCTX

    const { getEvent } = useEventApi()

    const { titleCase, isThisAPastTime, secondsSinceThisDate } = useShared();

    const { store, showModal } = useGlobalModalContext();
    const [currentSubView, setCurrentSubView] = useState<string>('loader');
    const [openModal, setOpenModal] = useState<string | undefined >();
    const [openSubModal, setOpenSubModal] = useState<boolean>(true);
    const [currentImage, setCurrentImage] = useState<string>();
    
    const props = { openModal, setOpenModal };

    const [eventToShow, setEventToShow] = useState<any>(event);
    
    // const [eventImage, setEventImage] = useState<any>();

    const getEventInfo = async () =>{
        // console.log('id: ', eventId)
        if (eventId){
            const apiCall = await getEvent({eventId}).then((result:any) => {
                // console.log('lol: ', result)
                if (result && result.res === 'success'){
                    // console.log(result.data[0])
                    setEventHasEnded(isThisAPastTime(result.data[0].endDate.toString() + ' ' + result.data[0].endTime.toString()))
                    dispatchAllEventsState({ 
                        type: 'SET_CURRENT_EVENT_ID', 
                        payload: result.data[0].id
                    });
                    setEventToShow(result.data[0])
                    document.title = `Festishare - You are invited to ${result.data[0].name}`
                    setCurrentSubView('default')
                }
            });
        }else{
            setEventToShow({})
          // return {}
        }
    }

    useEffect(()=>{
        if (eventId){
            // console.log(eventId)
            getEventInfo()
        }
    },[eventId])

    useEffect(()=>{
        if (event){
            setEventToShow(event)
        }
        // console.log('event: ', event)
    }, [event])

    const [loaded, setLoaded] = useState<boolean>(false);
    const [eventHasEnded, setEventHasEnded] = useState<boolean>(false);
    const [guests, setGuests] = useState<number>(0);
    const [remainingGuests, setRemainingGuests] = useState<number>(0);

    const topRef = useRef<null | HTMLDivElement>(null)

    const profileModal = (profileId:string) => {
        props.setOpenModal('initial-focus');
        showModal(MODAL_TYPES.PROFILE_MODAL, { title: "Create instance form", confirmBtn: "Close", profileType:'guest', profileId:profileId });
    };

    var rsvps = 0
    var remaining = 0

    useEffect(() => {
        if ( eventToShow ){
            var guestsValue = 0
            var remainingValue = 0
            for (const item of eventToShow.price) {
                guestsValue += item.tickets
                remainingValue += item.availableTickets;
            }
            setGuests(guestsValue)
            setRemainingGuests(remainingValue)
            rsvps -= (guests - remaining)
            
            setEventHasEnded(isThisAPastTime(eventToShow.endDate.toString() + ' ' + eventToShow.endTime.toString()))

            setCurrentSubView('default')
        }
    }, [eventToShow]);

    topRef.current?.scrollIntoView({ behavior: "smooth" });

    const stats = [
        { label: 'Guests', value: guests },
        { label: 'RSVPs', value: (guests - remainingGuests) },
        { label: 'Tickets remaining', value: remainingGuests },
    ]

    return (
        <div className="bg-white">
            <div ref={topRef} />

            {(currentSubView ==='loader')? <div className="mt-20"><Loader /></div>:null}

            {(currentSubView ==='default') && (Object.keys(eventToShow).length > 0)? 
                <main className="isolate">
                    {/* Hero section */}
                    <div className="relative isolate -z-10">
                        <svg
                            className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
                            aria-hidden="true"
                        >
                            <defs>
                            <pattern
                                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                                width={200}
                                height={200}
                                x="50%"
                                y={-1}
                                patternUnits="userSpaceOnUse"
                            >
                                <path d="M.5 200V.5H200" fill="none" />
                            </pattern>
                            </defs>
                            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                            <path
                                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                                strokeWidth={0}
                            />
                            </svg>
                            <rect width="100%" height="100%" strokeWidth={0} fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
                        </svg>

                        <div
                            className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
                            aria-hidden="true"
                        >
                            <div
                            className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
                            style={{
                                clipPath:
                                'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
                            }}
                            />
                        </div>
                        <div className="overflow-hidden">
                            <div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
                                <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                                    <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                                    <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                        {eventToShow.name}
                                    </h1>
                                    
                                    {(eventToShow.premium === 'yes')?
                                        <div>
                                            <div className='bg-green-100 text-green-800 inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'>
                                                <FaRegStar
                                                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                                                    aria-hidden="true"
                                                />
                                                Premium
                                            </div>
                                            <p className="relative mt-6 text-xs leading-6 text-red-700 sm:max-w-md lg:max-w-none">
                                                <b>Age Requirements: </b>{eventToShow.minAge.toString()} - {eventToShow.maxAge} Years old
                                            </p>
                                        </div>:null
                                    }
                                    <p className="relative mt-6 text-lg leading-6 text-gray-600 sm:max-w-md lg:max-w-none">
                                        <b>Event ID: </b> {eventToShow.id.slice(-6)}
                                    </p>
                                    <p className="relative mt-6 text-lg leading-3 text-gray-600 sm:max-w-md lg:max-w-none">
                                        <b>Event Type: </b> {eventToShow.type}
                                    </p>
                                    {eventPageType === 'myevent'? 
                                        <div className="relative mt-6 text-sm leading-3 text-gray-600 sm:max-w-md lg:max-w-none flex flex-wrap gap-6 ">
                                        
                                            <Button type="button" size='xs' color="light" pill disabled={true} >
                                                <b>Sales: $ {eventToShow.cash_balance}</b>
                                            </Button>
                    
                                            {eventHasEnded && (eventToShow.status === 'upcoming') && (eventToShow.cash_balance > 0)?
                                                <Button onClick={callBack} type="button" size='xs' gradientDuoTone="greenToBlue" pill >
                                                    Cash Out 
                                                </Button>:null
                                            }
                                        
                                        </div>:null
                                    }
                                    <p className="relative mt-6 text-xs leading-6 text-red-700 sm:max-w-md lg:max-w-none">
                                        <b>Event Time: </b>{eventToShow.startDate.toString()} at {eventToShow.startTime.toString()}&nbsp; <b>To</b>&nbsp;{eventToShow.endDate.toString()} at {eventToShow.endTime.toString()}
                                    </p>
                                    <p className="relative mt-6 text-sm leading-6 text-gray-600 sm:max-w-md lg:max-w-none">
                                        <b>Event Location: </b> {showAddress? titleCase(eventToShow.address) + ', ':''}&nbsp;{titleCase(eventToShow.city)},&nbsp;{titleCase(eventToShow.state)}&nbsp;/&nbsp;{titleCase(eventToShow.country)}
                                    </p>
                                    <p className="relative mt-6 text-sm leading-3 text-gray-600 sm:max-w-md lg:max-w-none">
                                        <b>Event ZipCode: </b> {eventToShow.postcode}
                                    </p>
                                    <p className="relative mt-6 text-sm leading-3 text-gray-600 sm:max-w-md lg:max-w-none">
                                        <b>Status: </b> {(eventToShow.status === 'upcoming' && isThisAPastTime(eventToShow.endDate))? 'Completed' : eventToShow.status} 
                                    </p>
                                    </div>
                                    {/* <div className="mt-3 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                                        <div className={"ml-auto flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80"}>
                                            <div className="relative">
                                            <img
                                                src={eventToShow.images[0]? eventToShow.images[0].link :  process.env.PUBLIC_URL + '/images/5.jpg'}
                                                alt=""
                                                className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                                onClick={()=>{setCurrentImage(eventToShow.images[0].link); setOpenSubModal(true); setCurrentSubView('images')}}
                                            />
                                            <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                            </div>
                                        </div> */}
                                        {/* Event images */}
                                        {/* <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                                            { (eventToShow.images[1]) && 
                                                <div className="relative">
                                                    <img
                                                        src={eventToShow.images[1]? eventToShow.images[1].link : process.env.PUBLIC_URL + '/images/2.jpg'} 
                                                        alt=""
                                                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                                    />
                                                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                                </div>
                                            }
                                            { (eventToShow.images[2]) && 
                                                <div className="relative">
                                                    <img
                                                        src={eventToShow.images[2]? eventToShow.images[2].link : process.env.PUBLIC_URL + '/images/3.jpg'}
                                                        alt=""
                                                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                                    />
                                                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                                </div> 
                                            }
                                        </div>
                                        <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                                            { (eventToShow.images[3]) && 
                                                <div className="relative">
                                                    <img
                                                        src={eventToShow.images[3]? eventToShow.images[3].link : process.env.PUBLIC_URL + '/images/4.jpg'}
                                                        alt=""
                                                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                                    />
                                                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                                </div>
                                            }
                                            { (eventToShow.images[4]) && 
                                                <div className="relative">
                                                    <img
                                                        src={eventToShow.images[4]? eventToShow.images[4].link : process.env.PUBLIC_URL + '/images/1.jpg'}
                                                        alt=""
                                                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                                    />
                                                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                                </div>
                                            } */}
                                    {/* </div> */}
                                </div> 
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
        
                    {/* Content section */}
                    <div className="mx-auto -mt-12 max-w-7xl px-6 sm:mt-0 lg:px-8 xl:-mt-8">
                        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Event Description</h2>
                            <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
                            <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
                                <p className="text-xl leading-8 text-gray-600">
                                {eventToShow.description? eventToShow.description : 'No Description' }
                                </p>
                                <div className="mt-10 max-w-xl text-base leading-7 text-gray-700">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Event Requirements</h2>
                                <p className="mt-3 text-xl leading-8 text-gray-600">
                                    {eventToShow.requirements? eventToShow.requirements : 'No Requirements' }
                                </p>
                                </div>
                            </div>
                            <div className="lg:flex lg:flex-auto lg:justify-center">
                                <dl className="w-64 space-y-8 xl:w-80">
                                {stats.map((stat) => (
                                    <div key={stat.label} className="flex flex-col-reverse gap-y-4">
                                    <dt className="text-base leading-7 text-gray-600">{stat.label}</dt>
                                    <dd className="text-5xl font-semibold tracking-tight text-gray-900">{stat.value}</dd>
                                    </div>
                                ))}
                                </dl>
                            </div>
                            </div>
                        </div>
                    </div>
        
                    {/* Image section */}
                    <div className="mt-12 sm:mt-20 xl:mx-auto xl:max-w-7xl xl:px-8">
                        <ImageCarousel images={eventToShow.images} />
                    </div>
        
                    {/* Prices section */}
                    <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Tickets Packages</h2>
                        {/* <p className="mt-6 text-lg leading-8 text-gray-600">
                        {event.requirements? event.requirements : 'No Requirements' }
                        </p> */}
                    </div>
                    
                    <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        {eventToShow.price.map((price:any) => (
                        <div key={price.name}>
                            <dt className="font-semibold text-gray-900">Package: {price.name}</dt>
                            <dd className="mt-1 text-xs text-gray-600">Description: {price.description} </dd>
                            <dd className="mt-1 text-xs text-gray-600">Total: {price.tickets} tickets </dd>
                            <dd className="mt-1 text-xs text-gray-600">Available: {price.availableTickets} tickets </dd>
                            <dd className="mt-1 text-xs text-gray-600">Price for Men: $ {price.priceMen} </dd>
                            <dd className="mt-1 text-xs text-gray-600">Price for ladies: $ {price.priceLadies} </dd>
                        </div>
                        ))}
                    </dl>
                    </div>
                    
                    {ticketCallBack?
                        <Button
                            className='fixed top-[210px] right-[5px] z-5'
                            onClick={()=> {return ticketCallBack('packages') }}
                            type="button"
                            gradientDuoTone="purpleToBlue" 
                            outline
                            pill
                        >   
                            <GiTicket  size={'27px'}/>
                            RSVP
                        </Button>:null
                    }
                    <div className='fixed top-[270px] right-[5px] z-5'>
                        <Share shareData={{ title: "Share Event", text: `This is the link for the event ${eventToShow.name} `, url: `${eventToShow.link}` }}>
                            <FaRegShareFromSquare />  Share
                        </Share>
                    </div>
      
                    {/* Logo cloud */}
                    {/* <div className="relative isolate -z-10 mt-32 sm:mt-48">
                    <div className="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 justify-center overflow-hidden [mask-image:radial-gradient(50%_45%_at_50%_55%,white,transparent)]">
                        <svg className="h-[40rem] w-[80rem] flex-none stroke-gray-200" aria-hidden="true">
                        <defs>
                            <pattern
                            id="e9033f3e-f665-41a6-84ef-756f6778e6fe"
                            width={200}
                            height={200}
                            x="50%"
                            y="50%"
                            patternUnits="userSpaceOnUse"
                            patternTransform="translate(-100 0)"
                            >
                            <path d="M.5 200V.5H200" fill="none" />
                            </pattern>
                        </defs>
                        <svg x="50%" y="50%" className="overflow-visible fill-gray-50">
                            <path d="M-300 0h201v201h-201Z M300 200h201v201h-201Z" strokeWidth={0} />
                        </svg>
                        <rect width="100%" height="100%" strokeWidth={0} fill="url(#e9033f3e-f665-41a6-84ef-756f6778e6fe)" />
                        </svg>
                    </div>
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
                        Trusted by the world’s most innovative teams
                        </h2>
                        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                        <img
                            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                            src="https://tailwindui.com/img/logos/158x48/transistor-logo-gray-900.svg"
                            alt="Transistor"
                            width={158}
                            height={48}
                        />
                        <img
                            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                            src="https://tailwindui.com/img/logos/158x48/reform-logo-gray-900.svg"
                            alt="Reform"
                            width={158}
                            height={48}
                        />
                        <img
                            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                            src="https://tailwindui.com/img/logos/158x48/tuple-logo-gray-900.svg"
                            alt="Tuple"
                            width={158}
                            height={48}
                        />
                        <img
                            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                            src="https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg"
                            alt="SavvyCal"
                            width={158}
                            height={48}
                        />
                        <img
                            className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                            src="https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg"
                            alt="Statamic"
                            width={158}
                            height={48}
                        />
                        </div>
                    </div>
                    </div> */}
      
                    {/* Team section */}
                    <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-48 lg:px-8">
                        <div className="mx-auto max-w-2xl lg:mx-0">
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Event Host:</h2>
                            {/* <p className="mt-6 text-lg leading-8 text-gray-600">
                            Sit facilis neque ab nulla vel. Cum eos in laudantium. Temporibus eos totam in dolorum. Nemo vel facere
                            repellendus ut eos dolores similique.
                            </p> */}
                        </div>
                        <ul
                            role="list"
                            className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
                        >
                            
                            {eventToShow.host.map((person:any) => (
                            <li key={person.name}>
                                <a onClick={(e) =>{profileModal(person.username); e.preventDefault();}} href='#'>
                                <img className="mx-auto h-24 w-24 rounded-full" src={person.profilePicture} alt="" />
                                <h3 className="mt-3 text-base font-semibold leading-7 tracking-tight text-gray-900">{person.name}.</h3>
                                <p className="text-sm leading-3 text-gray-600">@{person.username}</p>
                                </a>
                            </li>
                            ))}
                        </ul>
                    </div>
        
                    {/* Blog section */}
                    { (eventPageType !== 'myevent' && eventPageType !== 'myticket')? 
                        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
                            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Similar Events</h2>
                                <p className="mt-2 text-lg leading-8 text-gray-600">
                                    Below are some similar events
                                </p>
                            </div>
                            <EventsListHome eventType={eventToShow.type} purpose='similar events' eventCallBack={setEventToShow} />
                        </div> : null
                    }
                </main>:null
            }

            {/* {(currentSubView === 'images')? 
                <>
                    <Modal dismissible show={openSubModal} onClose={() => { setOpenSubModal(false); setCurrentSubView('default')}}>
                        <Modal.Header>Image</Modal.Header>
                        <Modal.Body>
                            <div className="space-y-6">
                                <img src={currentImage} className='w-full' alt="" />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button color="gray" onClick={() => { setOpenSubModal(false); setCurrentSubView('default')}}>
                            Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>:null
            } */}

            {(currentSubView ==='success')? 
                <AlertComponent 
                    callBack={() => {setCurrentSubView('default')}} 
                    type='success' 
                    subject='Success' 
                    message='Congratulations, you have successfully deleted this package ...' 
                />: null
            } 

            {(currentSubView ==='error')? 
                <AlertComponent 
                    callBack={() => {setCurrentSubView('default')}} 
                    type='error' 
                    subject='Failed' 
                    message='Sorry, an error occured ...' 
                />: null
            } 
        </div>
    )
}


export default EventDetails