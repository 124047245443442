// import { useEffect} from 'react';
import { useApi } from "./api"
import { EventDTO, EventImage, EventPromoter, EventTicketPackageDTO } from "../models"
import { useContextState } from '../context/Context';
// import Cookies from 'js-cookie';



export const useEventApi = () => {
    const { $post, $get, $put, $patch, $delete } = useApi()

    const { eventsCTX, paymentCTX, accountCTX } = useContextState();
    const { allEventsState : { pendingEvent }, dispatchAllEventsState } = eventsCTX
    const { paymentState, setPaymentState } = paymentCTX
    const { signedIn } = accountCTX


    const getEvent = async (credentials:{eventId:string, eventPageType?:string}) => {
        const path = 'events/' + credentials.eventId + '/'
        const useAuthentication = (credentials.eventPageType === 'myevent' || credentials.eventPageType === 'myticket')? true:false
        var data = await $get(path, useAuthentication, credentials)
        // console.log('data: ', data.data[0])
        if (data && (data.res === 'success')){
            dispatchAllEventsState({ type: 'SET_CURRENT_EVENT', payload: {event: data.data[0]}  })
            return data
        }
        else if (data && (data.res === 'failed')){
            return data
        }
        return 
    }

    const getEvents = async (resultIndex?:number) => {
        var path = 'events/?page=' + (resultIndex? resultIndex?.toString():'1')
        var data = await $get(path, false)
        // console.log(data)
        if (data && data.results){
            dispatchAllEventsState({ type: 'SET_EVENTS', payload: {events: data.results} })
        }
        return data
    }

    const getTickets = async (ticketId:string) => {
        const path = 'events/tickets/' + ticketId + '/'
        var data = await $get(path, false)
        if (data && (data.res === 'success' || data.res === 'failed')){
            return data
        }
        console.log('No data found')
        return 
    }

    const getMyEvent = async (credentials:{eventId:string, requester:string}) => {
        var data = await $post('events/get_account_event/', credentials, true)
        // console.log(data)
        if (data && data.length > 0){
            return data[0]
        }
        return 
    }

    const getMyEvents = async (eventStatus:'upcoming' | 'completed' | 'cancelled', resultIndex?:number) => {
        // console.log('starting ...', resultIndex)
        var path = 'events/get_account_events/?page=' + (resultIndex? resultIndex?.toString():'1')
        var data = await $post(path, {'requester':'host', 'status': eventStatus}, true)
        // console.log(data)
        if (data && data.results){
            const reducerMapping = {
                'upcoming': 'SET_MY_UPCOMING_EVENTS',
                'completed': 'SET_MY_COMPLETED_EVENTS',
                'cancelled': 'SET_MY_CANCELLED_EVENTS'
            }
            dispatchAllEventsState({ type: reducerMapping[eventStatus], payload: {events: data.results} })
            return data
        }
        return 
    }

    const getMyTickets = async (ticketsStatus:'upcoming' | 'completed' | 'cancelled' | 'request' | 'requestApproved' ,resultIndex?:number) => {
        // console.log('starting ...')
        var path = 'events/get_account_tickets/?page=' + (resultIndex? resultIndex?.toString():'1')
        var data = await $post(path, {'requester':'guest', 'status': ticketsStatus}, true)
        // console.log(data)
        if (data && data.results){
            const reducerMapping = {
                'upcoming': 'SET_MY_UPCOMING_TICKETS',
                'completed': 'SET_MY_COMPLETED_TICKETS',
                'cancelled': 'SET_MY_CANCELLED_TICKETS',
                'request': 'SET_MY_REQUESTS_TICKETS',
                'requestApproved': 'SET_MY_APPROVED_TICKETS'
            }
            dispatchAllEventsState({ type: reducerMapping[ticketsStatus], payload: {events: data.results} })
            return data
        }
        return 
    }
    
    const getPendingEvent = async () => {
        var data = await $get('events/get_pending_event/', true)
        // console.log(data)
        if (data && !!data.id){
            dispatchAllEventsState({ type: 'SET_PENDING_EVENT', payload: {event: data} })
        }
        return data
    }

    const createEvent = async (credentials: EventDTO) => {
        const data = await $post('events/', credentials, true)
        // console.log(data)
        if (!data) return
        if (!!data.id){
            dispatchAllEventsState({ type: 'UPDATE_PENDING_EVENT', payload: {event: data} })
        }
        return data
    }

    const updateEvent = async (credentials: EventDTO) => {
        const path = 'events/' + credentials.id + '/'
        const data = await $patch(path, credentials)
        // console.log(data)
        if (!data) return
        if (!!data.id){
            if (data.status === 'pending'){dispatchAllEventsState({ type: 'UPDATE_PENDING_EVENT', payload: {event: data} })}
            else {dispatchAllEventsState({ type: 'UPDATE_EVENT', payload: {event: data} })}
        }
        return data
    }

    const cancelEvent = async (credentials: {eventId:string, password:string}) => {
        const path = 'events/' + credentials.eventId + '/'
        const data = await $delete(path, credentials)
        if (!data) return
        if (data.res === 'success'){
            console.log('lol')
            dispatchAllEventsState({ type: 'CANCEL_EVENT', payload: {eventId: credentials.eventId,} })
        }
        return data
    }

    const createEventTicketPackage = async (credentials: EventTicketPackageDTO, stateType:string) => {
        const data = await $post('events/packages/', credentials, true)
        // console.log('data: ', data)
        if (!data) return
        if (!!data.id){
            if (stateType === 'pendingEvent'){
                dispatchAllEventsState({ type: 'UPDATE_PENDING_EVENT', payload: {event: { 'price':[data] }} })
            }
            else if (stateType === 'activeEvent'){
                dispatchAllEventsState({ type: 'ADD_EVENT_PRICE_PACKAGE', payload: {package: data , id: credentials.eventId }, })
            }
        }
        return data
    }

    const updateEventTicketPackage = async (credentials: EventTicketPackageDTO, stateType:string) => {
        const path = 'events/packages/' + credentials.id + '/'
        const data = await $put(path, credentials)
        // console.log(data)
        if (!data) return
        if (!!data.id){
            if (stateType === 'pendingEvent'){
                dispatchAllEventsState({ type: 'UPDATE_PENDING_EVENT', payload: {event: { 'price':[data] }} })
            }
            else if (stateType === 'activeEvent'){
                dispatchAllEventsState({ type: 'UPDATE_EVENT_PRICE_PACKAGE', payload: {package: data , id: credentials.eventId }, })
            }
        }
        return data
    }

    const deleteEventTicketPackage = async (packageId:string, eventId: string) => {
        const path = 'events/packages/' + packageId + '/'
        const data = await $delete(path)
        // console.log(data)
        if (!data) return
        if (data.res === 'Success'){
            dispatchAllEventsState({ type: 'DELETE_EVENT_PRICE_PACKAGE', payload: {id: eventId, packageId:packageId }, })
        }
        return (data.res === 'Success')
    }

    const sendEventTicketRequest = async (credentials: {eventId: string, packageId?:string }) => {
        // console.log(credentials)
        const data = await $post('events/tickets/', credentials, true)
        // console.log(data)
        if (!data) return
        if (data.res === 'success'){
            const event = await getMyEvent({eventId:credentials.eventId, requester:'guest'})
            // console.log(event)
            dispatchAllEventsState({ type: 'ADD_TICKET', payload: {event: event } })
        }
        return data
    }

    const approveEventTicketRequest = async (credentials: {eventId: string, ticketId:string }) => {
        const path = 'events/tickets/' + credentials.ticketId + '/'
        const data = await $put(path, credentials)
        if (!data) return
        if (data.res === 'success'){
            dispatchAllEventsState({ type: 'APPROVE_TICKET_REQUEST', payload: {eventId: credentials.eventId, guest:data.data  } })
        }
        return data
    }

    const cancelTicket = async (credentials: {eventId: string, ticketId:string, password:string }) => {
        const path = 'events/tickets/' + credentials.ticketId + '/'
        const data = await $delete(path, credentials)
        if (!data) return
        if (data.res === 'success'){
            dispatchAllEventsState({ type: 'CANCEL_TICKET', payload: { eventId: credentials.eventId, ticket: {'id': credentials.ticketId, 'status':'cancelled'} }})
        }
        return data
    }

    const scanGuest = async (credentials: {eventId: string, ticketId:string }) => {
        const data = await $post('events/scan_guest/', credentials, true)
        console.log(data)
        if (data.res === 'success' || data.res === 'failed'){
            return data
        }
        return 
    }

    const getNumberOfTicketsThatCanBeBought = async (credentials: {id:string, email?:string}) => {
        const data = await $post('getNumberOfTicketsThatCanBeBought/', credentials, false)
        // console.log(data)
        if (!(data.res === 'success')) return
        
        return data
    }

    const generatePendingTransactionCode = async (credentials: {id:string, transactionType:string, menQuantity?:string, ladiesQuantity?:string, email?:string}) => {
        const data = await $post('generatePendingTransactionCode/', credentials, signedIn?true:false)
        // console.log(data)
        if (!data) return
        if (!!data.refId){
            setPaymentState(data)
        }
        return data
    }

    const createIncomingPaypalTransaction = async (credentials: {
        orderID: string, eventId: string, packageId?:string, ticketId?:string, transactionType:string, transactionId:string, email?:string}) => {
        // console.log(credentials)
        const data = await $post('createIncomingPaypalTransaction/', credentials, signedIn?true:false)
        // console.log(data)
        if (!data) return
        if (data.res === 'success'){
            
            if (credentials.transactionType === 'event'){
                const event = await getMyEvent({eventId:credentials.eventId, requester:'host'})
                dispatchAllEventsState({ type: 'ADD_EVENT', payload: {event: event } })
            }
            else if (signedIn && credentials.transactionType === 'ticket'){
                const event = await getMyEvent({eventId:credentials.eventId, requester:'guest'})
                dispatchAllEventsState({ type: 'ADD_TICKET', payload: {event: event } })
            }
            else if (signedIn && credentials.transactionType === 'approvedRequestPayment'){
                // const event = await getMyEvent({eventId:credentials.eventId, requester:'guest'})
                // console.log(event)
                dispatchAllEventsState({ type: 'UPDATE_TICKET', payload: {eventId:credentials.eventId, ticket: data.data } })
            }
            // console.log('lol')
            setPaymentState({...paymentState, status:'success', id:data.id})
        }
        return data
    }

    const createIncomingFreeTransaction = async (credentials: {eventId: string, packageId?:string, transactionType:string, transactionId:string, email?:string}) => {
        // console.log(credentials)
        const data = await $post('createIncomingFreeTransaction/', credentials, false)
        // console.log(data)
        if (!data) return
        if (data.res === 'success'){
            
            if (credentials.transactionType === 'event'){
                const event = await getMyEvent({eventId:credentials.eventId, requester:'host'})
                dispatchAllEventsState({ type: 'ADD_EVENT', payload: {event: event } })
            }
            else if (signedIn && credentials.transactionType === 'ticket'){
                const event = await getMyEvent({eventId:credentials.eventId, requester:'guest'})
                // console.log(event)
                dispatchAllEventsState({ type: 'ADD_TICKET', payload: {event: event } })
            }
            // console.log('lol')
            setPaymentState({...paymentState, status:'success', id:data.id})
        }
        return data
    }

    const addEventPictures = async (images: any) => {
        // console.log(images.get('eventId'))
        const data = await $post('events/images/', images, true, false)
        // console.log(data)
        if (!data) return
        if (data){
            dispatchAllEventsState({ type: 'ADD_EVENT_PICTURES', payload: {images: data , id: images.get('eventId')} })
        }
        return data
    }

    const deleteEventPicture = async (image:EventImage, eventId: string) => {
        const path = 'events/images/' + image.id + '/'
        const data = await $delete(path)
        // console.log(data);
        if (!data) return
        if (data.res === 'Success'){
            dispatchAllEventsState({ type: 'DELETE_EVENT_PICTURE', payload: {id: eventId, imageId:image.id }, })
        }

        return (data.res === "Success")
    }

    const addEventPromoter = async (promoter: EventPromoter) => {
        // console.log(promoter)
        const data = await $post('events/promoter/', promoter, true, false)
        console.log(data)
        if (!data) return
        if (data){
            dispatchAllEventsState({ type: 'ADD_EVENT_PROMOTER', payload: {promoter: data , id: promoter.eventId} })
        }
        return data
    }

    const updateEventPromoter = async (promoter: EventPromoter) => {
        const path = 'events/promoter/' + promoter.id + '/'
        const data = await $patch(path, promoter)
        // console.log(data)
        if (!data) return
        if (!!data.id){
            dispatchAllEventsState({ type: 'UPDATE_EVENT_PROMOTER', payload: {promoter: data , id: promoter.eventId }, })
        }
        return data
    }

    const deleteEventPromoter = async (promoterId:string, eventId: string) => {
        const path = 'events/promoter/' + promoterId
        const data = await $delete(path)
        // console.log(data);
        if (!data) return
        if (!!data.id){
            // dispatchAllEventsState({ type: 'DELETE_EVENT_PROMOTER', payload: {id: eventId, promoterId:promoterId }, })
            dispatchAllEventsState({ type: 'UPDATE_EVENT_PROMOTER', payload: {promoter: data , id: eventId }, })
        }

        return data
    }

  

    return { 
        getEvent,
        getEvents,
        getTickets,
        getMyEvents,
        getMyTickets,
        getPendingEvent, 
        createEvent, 
        updateEvent,
        cancelEvent, 
        createEventTicketPackage, 
        updateEventTicketPackage, 
        deleteEventTicketPackage,
        addEventPictures, 
        deleteEventPicture,
        addEventPromoter,
        updateEventPromoter,
        deleteEventPromoter,
        sendEventTicketRequest,
        approveEventTicketRequest,
        cancelTicket,
        scanGuest,
        getNumberOfTicketsThatCanBeBought,
        generatePendingTransactionCode,
        createIncomingPaypalTransaction,
        createIncomingFreeTransaction,
    }
}
