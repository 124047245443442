import React, { useEffect, useState } from 'react'
import { useContextState } from "../../context/Context";
import SecondHeaderButton from './SecondHeaderButton'
// import { useSocialState } from '../../context/SocialContext'

// Icons
import { MdOutlineDirectionsBoatFilled, MdBusiness, MdPool, MdCancelPresentation, MdOutlineQrCode2 } from 'react-icons/md';
import { BsBusFront, BsFillHouseHeartFill, BsBuildings } from 'react-icons/bs';
// import { FaHotel} from 'react-icons/fa';
import { FaArrowsDownToPeople } from 'react-icons/fa6';
import { IoIosPeople, IoMdGitPullRequest } from 'react-icons/io';
import { GrChapterPrevious } from 'react-icons/gr';
import { TiMessages } from 'react-icons/ti';
import { GiTicket, GiThreeFriends, GiPartyPopper } from 'react-icons/gi';
import { VscSymbolEvent } from 'react-icons/vsc';
import { RiChatPrivateLine, RiUserReceived2Line, RiPassPendingLine } from 'react-icons/ri';
import { HiMiniUserGroup } from 'react-icons/hi2';
import { SiPrometheus, SiGithubactions } from 'react-icons/si';
import { CiEdit } from 'react-icons/ci';
import { AiOutlinePicture } from 'react-icons/ai';
import { TbUserQuestion } from "react-icons/tb";
// import { FcApprove } from "react-icons/fc";
import { MdApproval, MdOutlineCallMissedOutgoing } from "react-icons/md";



interface Props{
    headerType?:string;
    callBack?: React.Dispatch<React.SetStateAction<string>>;
}


const SecondHeader: React.FC<Props> = ({headerType, callBack}) => {
    // console.log( headerType.headerType)
    const { eventsCTX, socialCTX, inboxCTX, notificationCTX } = useContextState();
    const {
        allEventsState: { loved },
        dispatchAllEventsState,
        eventsDispatch,
    } = eventsCTX;

    const { 
        privateInboxUnreadMessagesCount,
        eventsInboxUnreadMessagesCount,
        privateAndEventsInboxUnreadMessagesCount
    } = notificationCTX;

    const { dispatchPeopleState } = socialCTX;

    const { dispatchInboxState } = inboxCTX;


    // HEADERS
    const headers = [
        {id: 2, name: 'Bus Parties', type: 'Bus Party', searchType: 'FILTER_BY_SEARCH', icon: BsBusFront },
        {id: 7, name: 'Pool Parties', type: 'Pool Party', searchType: 'FILTER_BY_SEARCH', icon: MdPool },
        {id: 1, name: 'Yacht Parties', type: 'Boat Party', searchType: 'FILTER_BY_SEARCH', icon: MdOutlineDirectionsBoatFilled },
        {id: 6, name: 'House Parties', type: 'House Party', searchType: 'FILTER_BY_SEARCH', icon: BsFillHouseHeartFill },
        // {id: 3, name: 'Clubs', type: 'Club', searchType: 'FILTER_BY_SEARCH', icon: MdBusiness },
        // {id: 4, name: 'Concerts', type: 'Concert', searchType: 'FILTER_BY_SEARCH', icon: IoIosPeople },
        // {id: 5, name: 'Hotel Parties', type: 'Hotel Party', searchType: 'FILTER_BY_SEARCH', icon: FaHotel },
        // {id: 8, name: 'Venues', type: 'Venue', searchType: 'FILTER_BY_SEARCH', icon: BsBuildings },
        {id: 9, name: 'Others', type: 'Other', searchType: 'FILTER_BY_SEARCH', icon: GiPartyPopper },
    ]
    
    // const myTicketsHeaders = [
    //     {id: 1, name: 'Upcoming', type: {'event': 'upcoming', 'ticket':'active', 'operator': 'AND'}, searchType: 'FILTER_BY_EVENT_AND_TICKET_STATUS', icon: GiTicket },
    //     {id: 2, name: 'Past Tickets', type: {'event': 'completed', 'ticket':'scanned', 'operator': 'OR'}, searchType: 'FILTER_BY_EVENT_AND_TICKET_STATUS', icon: GrChapterPrevious },
    //     {id: 3, name: 'Cancelled', type: {'event': 'cancelled', 'ticket':'cancelled', 'operator': 'OR'}, searchType: 'FILTER_BY_EVENT_AND_TICKET_STATUS', icon: MdCancelPresentation },
    //     {id: 4, name: 'Requests', type: {'event': 'upcoming', 'ticket':'request', 'operator': 'AND'}, searchType: 'FILTER_BY_EVENT_AND_TICKET_STATUS', icon: IoMdGitPullRequest },
    //     {id: 5, name: 'Approved', type: {'event': 'upcoming', 'ticket':'requestApproved', 'operator': 'AND'}, searchType: 'FILTER_BY_EVENT_AND_TICKET_STATUS', icon: MdApproval },
    // ]

    const myTicketsHeaders = [
        {id: 1, name: 'Upcoming', type: 'upcoming', searchType: 'FILTER_BY_STATUS', icon: GiTicket },
        {id: 2, name: 'Past Tickets', type: 'completed', searchType: 'FILTER_BY_STATUS', icon: GrChapterPrevious },
        {id: 3, name: 'Cancelled', type: 'cancelled', searchType: 'FILTER_BY_STATUS', icon: MdCancelPresentation },
        {id: 4, name: 'Requests', type: 'request', searchType: 'FILTER_BY_STATUS', icon: IoMdGitPullRequest },
        {id: 5, name: 'Approved', type: 'requestApproved', searchType: 'FILTER_BY_STATUS', icon: MdApproval },
    ]
    
    const myTicketEventHeaders = [
        {id: 1, name: 'Event', type: 'event', searchType: 'state', icon: GiPartyPopper },
        {id: 2, name: 'QR Code', type: 'qrcode', searchType: 'state', icon: MdOutlineQrCode2 },
        {id: 3, name: 'Tickets', type: 'tickets', searchType: 'state', icon: GiTicket },
        // {id: 4, name: 'Actions', type: 'action', searchType: 'state', icon: SiGithubactions },
    ]
    
    const myTicketRequestEventHeaders = [
        {id: 1, name: 'Event', type: 'event', searchType: 'state', icon: GiPartyPopper },
        {id: 2, name: 'Request', type: 'qrcode', searchType: 'state', icon: IoMdGitPullRequest },
        // {id: 4, name: 'Actions', type: 'action', searchType: 'state', icon: SiGithubactions },
    ]
    
    const myEventsHeaders = [
        {id: 1, name: 'Upcoming', type: 'upcoming', searchType: 'FILTER_BY_STATUS', icon: VscSymbolEvent },
        {id: 2, name: 'Past Events', type: 'completed', searchType: 'FILTER_BY_STATUS', icon: GrChapterPrevious },
        {id: 3, name: 'Cancelled', type: 'cancelled', searchType: 'FILTER_BY_STATUS', icon: MdCancelPresentation },
    ]
    
    const myEventEventHeaders = [
        {id: 1, name: 'Event', type: 'event', searchType: 'state', icon: GiPartyPopper },
        {id: 2, name: 'Edit', type: 'edit', searchType: 'state', icon: CiEdit },
        {id: 3, name: 'Guests', type: 'guests', searchType: 'state', icon: GiThreeFriends },
        {id: 4, name: 'Approved', type: 'requestApproved', searchType: 'state', icon: MdApproval },
        {id: 5, name: 'Requests', type: 'requests', searchType: 'state', icon: TbUserQuestion },
        {id: 6, name: 'Promoters', type: 'promoters', searchType: 'state', icon: SiPrometheus },
        {id: 7, name: 'Packages', type: 'packages', searchType: 'state', icon: GiTicket },
        {id: 8, name: 'Pictures', type: 'pictures', searchType: 'state', icon: AiOutlinePicture },
    ]
    
    const eventHeaders = [
        {id: 1, name: 'Event', type: 'event', searchType: 'state', icon: GiPartyPopper },
        {id: 5, name: 'Get Tickets', type: 'packages', searchType: 'state', icon: GiTicket },
        {id: 6, name: 'Pictures', type: 'pictures', searchType: 'state', icon: AiOutlinePicture },
    ]
    
    const socialHeaders = [
        {id: 1, name: 'Friends', type: 'friends', searchType: 'peopleState', icon: GiThreeFriends },
        {id: 2, name: 'Pending', type: 'requestsSent', searchType: 'peopleState', icon: RiPassPendingLine },
        {id: 3, name: 'Requests', type: 'requestsReceived', searchType: 'peopleState', icon: RiUserReceived2Line },
        {id: 4, name: 'Discovery', type: 'potentialFriends', searchType: 'peopleState', icon: FaArrowsDownToPeople },
        {id: 5, name: 'Inbox', type: '/inbox', searchType: 'link', icon: TiMessages, notificationState: privateAndEventsInboxUnreadMessagesCount },
    ]
    
    const messagesHeaders = [
        {id: 1, name: 'Privates', type: 'private', searchType: 'inboxState', icon: RiChatPrivateLine, notificationState: privateInboxUnreadMessagesCount },
        {id: 2, name: 'Events', type: 'event', searchType: 'inboxState', icon: HiMiniUserGroup, notificationState: eventsInboxUnreadMessagesCount },
    ]

    
    // STATES AND FUNCTIONS
    const [selected, setSelected] = useState(Number);
    const [initialLoad, setInitialLoad] = useState<boolean>(true)

    const handleHeader = (header:SecondHeaderButton) => {

        // console.log(header)
        setSelected(header.id);
        if (header.searchType === 'link'){
            window.location.href = header.type
        }
        else if (header.searchType === 'peopleState'){
            dispatchPeopleState({ type: 'SET_SOCIAL_STATE', payload: header.type })
        }
        else if (header.searchType === 'inboxState'){
            dispatchInboxState({ type: 'SET_INBOX_STATE', payload: header.type })
        }
        else if (header.searchType === 'state'){
            if (typeof callBack !== 'undefined'){callBack(header.type)}
        }
        else {
            eventsDispatch({
                type: header.searchType,
                payload: header.type
            });
        }
    };


    var thisHeader:any[] = headers;
    if (headerType && (typeof headerType === 'string')){
        if (headerType === 'mytickets'){
            thisHeader = myTicketsHeaders;
        }
        else if (headerType === 'myticket'){
            thisHeader = myTicketEventHeaders;
        }
        else if (headerType === 'myrequest'){
            thisHeader = myTicketRequestEventHeaders;
        }
        else if (headerType === 'myevents'){
            thisHeader = myEventsHeaders;
        }
        else if (headerType === 'myevent'){
            thisHeader = myEventEventHeaders;
        }
        else if (headerType === 'event'){
            thisHeader = eventHeaders;
        }
        else if (headerType === 'social'){
            thisHeader = socialHeaders;
        }
        else if (headerType === 'messages'){
            thisHeader = messagesHeaders;
        }
    }

    useEffect(()=>{
        if (initialLoad && headerType && (headerType !== '/')){
            handleHeader(thisHeader[0])
        }
    }, [initialLoad])

    
    // console.log(thisHeader)
    var justifyCenter = ''
    if (thisHeader.length < 5){justifyCenter='justify-center'}


    return (
        <div className="sticky top-20 z-10 " style={{ backgroundColor: "white" }}>
            <div className="" >
                <div className={"sticky flex lg:flex-1 rounded-md shadow-md lg:justify-center space-x-2 overflow-x-auto w-full " + justifyCenter }> 
                    { thisHeader.map((header) => (
                        <button 
                            className={"group block flex-shrink-0 p-2 focus:bg-gray-100 bg-white-50 " }
                            key={header.id}
                            onClick={() => {setInitialLoad(false); return handleHeader(header)}}
                            style={{ backgroundColor: (header.id === selected || (initialLoad && header.id === 1 && thisHeader !== headers))  ? "gray" : "white" }}
                        >   
                            <SecondHeaderButton button1={header} key={header.id} />
                        </button>
                    ))}
                </div>
                
            </div>
        </div>
    )
    
}

export default SecondHeader