'use client';

import { Carousel } from 'flowbite-react';
import logo from '../../logo.png';



interface Props{
    images: any[];
}

const ImageCarousel : React.FC<Props> = ({images}) => {
    // console.log(images.length);
    if (images.length === 0){
      images = [{'link': 'https://firebasestorage.googleapis.com/v0/b/partyexpress360-d40e5.appspot.com/o/FCMImages%2F256x256.png?alt=media&token=6a788c81-a716-4a9d-8eb6-24e331fe2d42'}]
    }

    return (
      <div className="overflow-hidden relative h-56 rounded-lg sm:h-64 xl:h-80 2xl:h-96">
        {/* <div className="overflow-hidden relative h-56 rounded-lg sm:h-64 xl:h-80 2xl:h-96"> */}
        <Carousel pauseOnHover>
            {images.map((image:any, index) => (
              <div key={index} className=" duration-900 ease-in-out " data-carousel-item>
                <img key={index} className="aspect-auto w-full rounded-2xl object-cover"  src={image.link} alt="Event Image" />
              </div>
            ))}
        </Carousel>
      </div>
    )
}

export default ImageCarousel
