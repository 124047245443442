import React, { useState } from 'react'
import FloatingLabel, { Button, Checkbox, Label, Modal, Select, Progress, Textarea } from 'flowbite-react';
import { Event, EventDTO } from '../../models';
import { useEventApi } from "../../store";
import Loader from '../shared/Loader';
import AlertComponent from '../shared/AlertComponent';
import validator from 'validator';

interface Props{
    event: any;
    type? : string;
    showEventInfoSubmit? : boolean;
    callBack: () => void;
}

const EventInfo: React.FC<Props> = ({event, type, showEventInfoSubmit, callBack}) => {

    const [currentSubView, setCurrentSubView] = useState<string>('edit');
    const [eventData, setEventData] = useState<any>(event);

    const { updateEvent } = useEventApi();

    const [ timeIsValid, setTimeIsValid ] = useState<boolean>(false);
    const [message, setMessage] = useState<string>();

    const [startDate, setStartDate] = useState<string>();
    const [errorStartDate, setErrorStartDate] = useState<string>();
    const [errorEndDate, setErrorEndDate] = useState<string>();

    const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;

    const checkDateValidity = (date:string) => {
        if (!dateRegex.test(date)) {
            return false
        } else {
            return true
        }
    }

    const handleDateValidation = (type:string, date:string) => {
        handleTimeValidation()
        if (!checkDateValidity(date)){
            type==='start'? setErrorStartDate("Enter a valid date format!"): setErrorEndDate("Enter a valid date format!")
        }
        else {
            type==='start'? setErrorStartDate(""): setErrorEndDate("")
        }
    }

    const handleTimeValidation = () => {
        if (eventData.status === 'pending'){
            // 1. Get input values as strings
            const startDateString = (document.getElementById('startDate') as HTMLInputElement).value;
            const startTimeString = (document.getElementById('startTime') as HTMLInputElement).value;
            const endDateString = (document.getElementById('endDate') as HTMLInputElement).value;
            const endTimeString = (document.getElementById('endTime') as HTMLInputElement).value;

            // 2. Create Date objects with proper formatting
            const startTime = new Date(`${startDateString}T${startTimeString}`); // Combine date and time correctly
            const endTime = new Date(`${endDateString}T${endTimeString}`);

            // 3. Compare timestamps for validation
            if (endTime.getTime() <= startTime.getTime()) {
                // console.error("End time must be after start time");
                setTimeIsValid(false)
                return false; // Indicate validation failure
            }

            setTimeIsValid(true)
        }

        return true
    }

    const handleSuccess = () => {
        if (type && (type === 'pendingEvent')){
            return callBack()
        }
        else {
            return setCurrentSubView('edit')
        }
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setCurrentSubView('loader')

        const checkTimeInputs =  handleTimeValidation()
        if (!checkTimeInputs){
            setMessage("Kindly make sure that the end time is later than the start time")
            setCurrentSubView('error')
            return ;
        }

        const data = {
            ...event,
            name: e.currentTarget.eventName.value,
            type: e.currentTarget.eventType.value,
            description: e.currentTarget.description.value,
            requirements: e.currentTarget.requirements.value,
            // Conditionally include date/time fields if the event is not yet submitted
            ...(event.status === 'pending' && {
              startDate: e.currentTarget.startDate.value,
              startTime: e.currentTarget.startTime.value,
              endDate: e.currentTarget.endDate.value,
              endTime: e.currentTarget.endTime.value,
            }),
        };


        const apiCall = await updateEvent(data)
        .then((result:any) => {
            if (result){
                if (type && type === 'pendingEvent'){ return handleSuccess() }
                else{setEventData(result); setCurrentSubView('success')}}
            else{setMessage("Sorry, an error occured ..."); setCurrentSubView('error')}
        });
    };

    var classNameValue = '';
    if (!type || type !== 'pendingEvent'){
        classNameValue = "border-gray-900/10  flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 sm:mx-auto sm:w-full sm:max-w-sm"
    }
    
    return (
        <div>
            <div className={classNameValue}>
                <h2 className="text-base font-semibold leading-7 text-gray-900">Information about the Event</h2>

                {(currentSubView ==='loader')? <Loader />:null}

                {(currentSubView === 'edit') && showEventInfoSubmit?
                    <form onSubmit={handleSubmit}>
                        <div className="">
                            <div className="mb-2 block mt-6">
                                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Event name
                                </label>
                                <div className="mt-2">
                                <input
                                    type="text"
                                    name="first-name"
                                    id="eventName"
                                    required
                                    defaultValue={eventData.name? eventData.name: ''}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                </div>
                            </div>

                            <div className=" border-gray-900/10 mt-6">
                                <div className="mb-2 block">
                                    <Label htmlFor="eventType" value="Choose event type " />
                                </div>
                                <Select
                                    id="eventType"
                                    required
                                    defaultValue={eventData.type? eventData.type: ''}
                                >
                                    <option></option>
                                    {/* <option value={'Boat Party'}>Boat Party</option> */}
                                    <option value={'Bus Party'}>Bus Party</option>
                                    {/* <option value={'Club'}>Club</option>
                                    <option value={'Concert'}>Concert</option>
                                    <option value={'Hotel Party'}>Hotel Party</option> */}
                                    <option value={'House Party'}>House Party</option>
                                    <option value={'Pool Party'}>Pool Party</option>
                                    <option value={'Yacht Party'}>Yacht Party</option>
                                    {/* <option value={'Venue'}>Venue Party</option> */}
                                    <option value={'Other'}>Other</option>
                                </Select>
                            </div>

                            <div className="sm:col-span-3 mt-6">
                                <label htmlFor="description" className="block text-sm font-medium text-gray-900">
                                    Event Description
                                </label>
                                <div className="mt-2">
                                <Textarea
                                    name="description"
                                    id="description"
                                    placeholder="Enter a description for your event ..."
                                    rows={4}
                                    required
                                    defaultValue={eventData.description? eventData.description: ''}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                </div>
                            </div>

                            <div className="sm:col-span-3 mt-6">
                                <label htmlFor="description" className="block text-sm font-medium text-gray-900">
                                    Event Requirements (Optional)
                                </label>
                                <div className="mt-2">
                                <Textarea
                                    name="requirements"
                                    id="requirement"
                                    placeholder="Enter a description for your event ..."
                                    rows={4}
                                    defaultValue={eventData.requirements? eventData.requirements: ''}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                </div>
                            </div>
                            {eventData.status === 'pending'?
                                <div>
                                    <div className="border-t border-gray-900/10   mt-6">
                                        <div className="mb-2 block">
                                            <Label htmlFor="startDate" value="Event start date" />
                                        </div>
                                        <input 
                                            className="fblock w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            type="date" 
                                            id="startDate" 
                                            name="trip-start" 
                                            required
                                            defaultValue={eventData.startDate? eventData.startDate: new Date().toJSON().slice(0,10)}
                                            min={new Date().toJSON().slice(0,10)} 
                                            onChange={(e) => handleDateValidation('start', e.target.value)}
                                        />
                                        {errorStartDate ? ( // display the error message if any
                                            <span className="text-red-600">{errorStartDate}</span>
                                            ) : null
                                        }
                                    </div>

                                    <div className="sm:col-span-4 mt-2 pb-3 border-b">
                                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                            Event start time
                                        </label>
                                        <div className="mt-2">
                                            <input 
                                                type="time" 
                                                className="fblock w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
                                                id="startTime" 
                                                required 
                                                defaultValue={eventData.startTime? eventData.startTime: null}
                                                name="startTime" 
                                                onChange={handleTimeValidation}
                                            >
                                            </input>
                                            { !timeIsValid ? ( // display the error message if any
                                                <span className="text-red-600">Kindly make sure that the end time is later that the start time</span>
                                                ) : null
                                            }
                                        </div>
                                    </div>

                                    <div className=" border-gray-900/10 mt-6">
                                        <div className="mb-2 block">
                                            <Label htmlFor="endDate" value="When will the event end" />
                                        </div>
                                        <input 
                                            className="fblock w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            type="date" 
                                            id="endDate" 
                                            name="endDate" 
                                            required
                                            key={eventData.endDate? eventData.endDate: startDate? new Date(startDate).toJSON().slice(0,10): new Date().toJSON().slice(0,10)}
                                            defaultValue={eventData.endDate? eventData.endDate: startDate? new Date(startDate).toJSON().slice(0,10): new Date().toJSON().slice(0,10)}
                                            min={
                                                (document.getElementById('startDate') as HTMLInputElement)?.value? 
                                                    new Date((document.getElementById('startDate') as HTMLInputElement).value).toJSON().slice(0,10): new Date().toJSON().slice(0,10)
                                            }
                                            onChange={(e) => handleDateValidation('end', e.target.value)}
                                        />
                                        {errorEndDate ? ( // display the error message if any
                                            <span className="text-red-600">{errorEndDate}</span>
                                            ) : null
                                        }
                                    </div>

                                    <div className="border-b sm:col-span-4 mb-3 pb-12 mt-2">
                                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                            Event end time
                                        </label>
                                        <div className="mt-2">
                                            <input 
                                                type="time" 
                                                className="fblock w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
                                                id="endTime" 
                                                required 
                                                defaultValue={eventData.endTime? eventData.endTime: null}
                                                name="endTime" 
                                                onChange={handleTimeValidation}
                                            >
                                            </input>
                                            { !timeIsValid ? ( // display the error message if any
                                                <span className="text-red-600">Kindly make sure that the end time is later that the start time</span>
                                                ) : null
                                            }
                                        </div>
                                    </div>

                                </div>:null
                            }
                            
                            <div className="w-full flex justify-center mt-6">
                                <Button type='submit'>Continue</Button>
                            </div>
                        </div>
                    </form>
                    :null
                }

                {(currentSubView === 'edit') && !showEventInfoSubmit?
                    <div className="">
                        <div className="mb-2 block mt-6">
                            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                Event name
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="first-name"
                                    id="eventName"
                                    required
                                    defaultValue={eventData.name? eventData.name: ''}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className=" border-gray-900/10 mt-6">
                            <div className="mb-2 block">
                                <Label htmlFor="eventType" value="Choose event type " />
                            </div>
                            <Select
                                id="eventType"
                                required
                                defaultValue={eventData.type? eventData.type: ''}
                            >
                                <option></option>
                                {/* <option value={'Boat Party'}>Boat Party</option> */}
                                <option value={'Bus Party'}>Bus Party</option>
                                {/* <option value={'Club'}>Club</option> */}
                                {/* <option value={'Concert'}>Concert</option> */}
                                {/* <option value={'Hotel Party'}>Hotel Party</option> */}
                                <option value={'House Party'}>House Party</option>
                                <option value={'Pool Party'}>Pool Party</option>
                                <option value={'Yacht Party'}>Yacht Party</option>
                                {/* <option value={'Venue'}>Venue Party</option> */}
                                <option value={'Other'}>Other</option>
                            </Select>
                        </div>

                        <div className="sm:col-span-3 mt-6">
                            <label htmlFor="description" className="block text-sm font-medium text-gray-900">
                                Event Description
                            </label>
                            <div className="mt-2">
                            <Textarea
                                name="description"
                                id="description"
                                placeholder="Enter a description for your event ..."
                                rows={4}
                                required
                                defaultValue={eventData.description? eventData.description: ''}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            </div>
                        </div>

                        <div className="sm:col-span-3 mt-6">
                            <label htmlFor="description" className="block text-sm font-medium text-gray-900">
                                Event Requirements
                            </label>
                            <div className="mt-2">
                            <Textarea
                                name="requirements"
                                id="requirement"
                                placeholder="Enter a description for your event ..."
                                rows={4}
                                defaultValue={eventData.requirements? eventData.requirements: ''}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            </div>
                        </div>

                        <div className="border-t border-gray-900/10   mt-6">
                            <div className="mb-2 block">
                                <Label htmlFor="startDate" value="Event start date" />
                            </div>
                            <input 
                                className="fblock w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                type="date" 
                                id="startDate" 
                                name="trip-start" 
                                required
                                defaultValue={eventData.startDate? eventData.startDate: new Date().toJSON().slice(0,10)}
                                min={new Date().toJSON().slice(0,10)} 
                                onChange={(e) => handleDateValidation('start', e.target.value)}
                            />
                        </div>

                        <div className="sm:col-span-4 mt-2 pb-3 border-b">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Event start time
                            </label>
                            <div className="mt-2">
                                <input 
                                    type="time" 
                                    className="fblock w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
                                    id="startTime" 
                                    required 
                                    defaultValue={eventData.startTime? eventData.startTime: null}
                                    name="startTime" 
                                    onChange={handleTimeValidation}
                                >
                                </input>
                                { !timeIsValid ? ( // display the error message if any
                                    <span className="text-red-600">Kindly make sure that the end time is later that the start time</span>
                                    ) : null
                                }
                            </div>
                        </div>

                        <div className=" border-gray-900/10 mt-6">
                            <div className="mb-2 block">
                                <Label htmlFor="endDate" value="When will the event end" />
                            </div>
                            <input 
                                className="fblock w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                type="date" 
                                id="endDate" 
                                name="endDate" 
                                required
                                key={eventData.endDate? eventData.endDate: startDate? new Date(startDate).toJSON().slice(0,10): new Date().toJSON().slice(0,10)}
                                defaultValue={eventData.endDate? eventData.endDate: startDate? new Date(startDate).toJSON().slice(0,10): new Date().toJSON().slice(0,10)}
                                min={
                                    (document.getElementById('startDate') as HTMLInputElement)?.value? 
                                        new Date((document.getElementById('startDate') as HTMLInputElement).value).toJSON().slice(0,10): new Date().toJSON().slice(0,10)
                                } 
                                onChange={(e) => handleDateValidation('end', e.target.value)}
                            />
                        </div>

                        <div className="border-b sm:col-span-4 mb-3 pb-12 mt-2">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Event end time
                            </label>
                            <div className="mt-2">
                                <input 
                                    type="time" 
                                    className="fblock w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
                                    id="endTime" 
                                    required 
                                    defaultValue={eventData.endTime? eventData.endTime: null}
                                    name="endTime" 
                                    onChange={handleTimeValidation}
                                >
                                </input>
                                { !timeIsValid ? ( // display the error message if any
                                    <span className="text-red-600">Kindly make sure that the end time is later that the start time</span>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div> : null
                }

                {(currentSubView ==='success')? 
                    <AlertComponent
                        callBack={()=>{return handleSuccess()}}
                        type='success' 
                        subject='Success' 
                        message='Congratulations, you have successfully updated the information about this event ...' 
                    />: null
                } 

                {(currentSubView ==='error')? 
                    <AlertComponent 
                        callBack={() => (setCurrentSubView('edit'))} 
                        type='error' 
                        subject='Failed' 
                        message={message as string} 
                    />: null
                }
            </div>
        </div>
    )
}

export default EventInfo