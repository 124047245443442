import {useState } from 'react'
import { Button, Card, Modal } from 'flowbite-react'
import { useContextState } from '../../context/Context'
import { EventImage } from '../../models';
import { useEventApi } from "../../store";
import Loader from '../shared/Loader';
import AlertComponent from '../shared/AlertComponent';

interface Props{
    callBack?: () => void;
    eventHasEnded? : boolean;
}

const EventPictures: React.FC<Props> = ({callBack, eventHasEnded}) => {
    const { eventsCTX } = useContextState();
    const { allEventsState: { currentEvent }, eventPageType } = eventsCTX
    const { deleteEventPicture } = useEventApi();

    const [currentSubView, setCurrentSubView] = useState<string>('default');
    const [currentImage, setCurrentImage] = useState<string>();
    const [openModal, setOpenModal] = useState<boolean>(true);

    // console.log('image:', currentEvent.images)

    var imagesList: EventImage[] = []
    if (currentEvent && currentEvent.images){
        imagesList = currentEvent.images
    }


    const handleDelete = async (id:any) => {
        setCurrentSubView('loader')

        const data = {'id': id}
        var apiCall = await deleteEventPicture(data, currentEvent.id)
        .then((result:any) => {
            if (result){setCurrentSubView('success')}else{setCurrentSubView('error')}
        });
        
    };


    return (
        <div className='mt-24'>
            {(currentSubView ==='loader')? <Loader />: null} 

            {(currentSubView ==='default')? 
                <div>
                    { (eventPageType === 'myevent' && callBack) && (!eventHasEnded)?
                        <div className="mt-10 flex items-center justify-center gap-x-6">
                            <a
                            href="#"
                            onClick={callBack}
                            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >Add pictures</a>
                        </div>:null
                    }

                    <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 mt-6">
                        {imagesList.map((image, index) => (
                            <li key={index} className="relative">
                                <Card
                                    className="max-w-sm"
                                    imgAlt="Event Image"
                                    imgSrc={image.link}
                                    onClick={()=>{setCurrentImage(image.link); setOpenModal(true); setCurrentSubView('images')}}
                                    // href=''
                                >
                                    <div className="flex items-center justify-between">
                                        <a
                                            href="#"
                                            onClick={()=>{setCurrentImage(image.link); setOpenModal(true); setCurrentSubView('images')}}
                                            className="rounded-lg bg-blue-600 px-3 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                        >
                                            View
                                        </a>
                                        { (eventPageType === 'myevent') && (!eventHasEnded) &&
                                            <a
                                                href="#"
                                                onClick={()=>{handleDelete(image.id)}}
                                                className="rounded-lg bg-red-600 px-3 py-2.5 text-center text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 dark:bg-cyan-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                                            >
                                                Delete
                                            </a>
                                        }
                                    </div>
                                    
                                </Card>
                                {/* <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                                    <img src={image.link} alt="Event Picture" className="pointer-events-none object-cover group-hover:opacity-75" />
                                    <button type="button" className="absolute inset-0 focus:outline-none">
                                        <span className="sr-only">View details for </span>
                                    </button>
                                </div> */}
                                {/* { (eventPageType === 'myevent') && (!eventHasEnded)?
                                    <div className="w-full flex justify-center mt-1 ">
                                        <Button onClick={()=>{handleDelete(image.id)}} className='bg-red-600' type='submit'  >Delete</Button>
                                    </div>:null
                                } */}
                            </li>
                        ))}
                        { imagesList?.length === 0 ? <p>No images yet.</p>:null}
                    </ul>
                </div>: null
            } 

            {(currentSubView === 'images')? 
                <>
                    <Modal dismissible show={openModal} onClose={() => { setOpenModal(false); setCurrentSubView('default')}}>
                        <Modal.Header>Image</Modal.Header>
                        <Modal.Body>
                            <div className="space-y-6">
                                <img src={currentImage} className='w-full' alt="" />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button color="gray" onClick={() => { setOpenModal(false); setCurrentSubView('default')}}>
                            Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
              </>:null
            }

            {(currentSubView ==='success')? 
                <AlertComponent callBack={() => {setCurrentSubView('default')}} 
                type='success' 
                subject='Success' 
                message='Congratulations, you have successfully deleted this image ...' />: null
            } 

            {(currentSubView ==='error')? 
                <AlertComponent callBack={() => {setCurrentSubView('default')}} 
                type='error' 
                subject='Failed' 
                message='Sorry, an error occured ...' />: null
            } 


        </div>
    )
}

export default EventPictures