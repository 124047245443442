'use client';
import React from 'react'
import { IconType } from 'react-icons';
import { Carousel } from 'flowbite-react';
import EventCardImageCarousel from '../images/EventCardImageCarousel';
import { Event } from "../../models"
import { useShared } from '../../store';
import { FaRegStar } from "react-icons/fa";


interface Props{
    event: Event;
    // onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const EventCard : React.FC<Props> = ({event}) => {

    const { titleCase } = useShared();

    var min:number  = Number.MAX_VALUE;
    var max: number = -Number.MAX_VALUE;
    var tickets = 0;
    var availableTickets =0;

    event.price.forEach(function (o:any) {
        tickets += o['tickets'];
        availableTickets += o['availableTickets']
        Object.keys(o).forEach(function (k) {                
            if ((k === 'priceMen' || k === 'priceLadies') && o[k] !== null) {
                min = Math.min(min, o[k]);
                max = Math.max(max, o[k]);
                
            }
        });
    });
    

  return (
    <div>
        <li key={event.id} className="relative">
            <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg  focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                <EventCardImageCarousel images={event.images} />
                <div className="flex justify-between">
                    <h3 className="text-lg font-semibold tracking-tight text-gray-900">{event.name}</h3>
                    {(event.premium === 'yes')?
                        <div className='bg-green-100 text-green-800 inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'>
                            <FaRegStar
                                className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                                aria-hidden="true"
                            />
                            Premium
                        </div>:null
                    }
                </div>
                <p className="text-xs tracking-tight text-red-700">From {event.startDate.toString()} at {event.startTime.toString()} To {event.endDate.toString()} at {event.endTime.toString()} </p> 
                <p className="text-base tracking-tight text-gray-600">{titleCase(event.city)}, {titleCase(event.state)} / {titleCase(event.country)}</p> 
                <h6 className="text-xs font-medium text-gray-700 group-hover:text-gray-900 ">ZipCode: {event.postcode}</h6>      
                <h6 className="text-xs  font-medium text-gray-700 group-hover:text-gray-900 ">{event.type}</h6>   
                <p className="text-base tracking-tight text-gray-600"><b>$ {min} - $ {max} </b></p>      
            </div> 
        </li>
    </div>
  )
}

export default EventCard

